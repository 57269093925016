import React, { Component } from "react";
import { Segment, Grid, Header, Image } from "semantic-ui-react";
import pixelRich from "../img/pixel-rich.png";
import pixelAdam from "../img/pixel-adam.png";
import pixelFuzz from "../img/pixel-fuzz.png";
import band from "../img/shoes_on_now_band_photo.jpg";

class Bio extends Component {
  render() {
    return (
      <Segment inverted color="pink" attached id="bio">
        <Header as="h1">BIO</Header>
        <Grid stackable doubling centered columns={3} className="bio-photos">
          <Grid.Row>
            <Image src={band} fluid bordered centered />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Image src={pixelFuzz} size="medium" bordered centered />
            </Grid.Column>
            <Grid.Column>
              <Image src={pixelAdam} size="medium" bordered centered />
            </Grid.Column>
            <Grid.Column>
              <Image src={pixelRich} size="medium" bordered centered />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        
        <Grid stackable columns={2}>
          <Grid.Column>
            <p>
              <strong>Shoes On, Now!</strong> are a hip cool young band of
              30-something dads who used to be in punk bands. They formed the
              band in January 2018, shortly after deciding they wanted to form a
              band together.
            </p>
            <p>
              Their first album,{" "}
              <a
                href="https://distrokid.com/hyperfollow/shoesonnow/fwJB"
                target="_blank"
                rel="noopener noreferrer"
              >
                Backwards Compatible
              </a>
              , is a 12-track mish-mash of chiptune, punk, new wave and{" "}
              <a
                href="https://en.wikipedia.org/wiki/Raclette"
                target="_blank"
                rel="noopener noreferrer"
              >
                raclette
              </a>
              . It was written, arranged, recorded and produced by the band over
              a 12(ish) month period in between working, making dinner, watching
              Netflix and getting frustrated by how long it takes to get their
              children ready to leave the house.
            </p>
            <p>
              Backwards compatible was recorded in Adam’s shed using Ableton Live,{" "}
              <a
                href="https://beepbox.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                BeepBox
              </a>
              , a single microphone designed especially for use in a business
              conference environment, zero amps and a repurposed strimmer that
              served as both a mic and lyrics stand. Oh, and they played{" "}
              <a
                href="https://www.instagram.com/p/BmHgIizhNMQ/"
                target="_blank"
                rel="noopener noreferrer"
              >
                guitar through a Game Boy
              </a>{" "}
              on a bunch of songs. That was fun...
            </p>

            <p>
              The album, released on Xmas Eve 2018 via advent calendar, is{" "}
              <a
                href="https://distrokid.com/hyperfollow/shoesonnow/fwJB"
                target="_blank"
                rel="noopener noreferrer"
              >
                available on all the online platforms
              </a>{" "}
              that helped make HMV redundant. 
            </p>
          </Grid.Column>
          <Grid.Column>
            
            <p>
              In 2019 they spent six months and a load of money rehearsing to play two gigs in exchange for £30 and a beer. Then, feeling flush they started work on a new EP. In the heat of summer, in the sweaty shed, they were distracted by the sound of jingle bells; putting on woolly jumpers and silly hats to record a <a href="https://www.youtube.com/watch?v=omZzrliLr_U">future christmas classic</a>.  
            </p>

            <p>2020: It turns out the boys had more to say about the inner emotional lives of their favourite retro plumbing game heroes, and the EP <em>blossomed</em> into an LP which they will be making you listen to in early 2021.</p>

            <p>
              They are very much looking forward to meeting you and have heard a
              lot about you.
            </p>

            <p>
              <strong>Shoes On, Now!</strong> are...
            </p>
            <p>
              <strong>Rich</strong> Vocals, Guitar, Programming Drums and Synths
            </p>
            <p>
              <strong>Fuzz</strong> Guitar, Chiptune/Renoise, Fuzz Pedals, BVs
            </p>
            <p>
              <strong>Adam</strong> Bass, Production, Mixing, BVs
            </p>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

export default Bio;
