import React, { Component } from "react";
import { Segment, Grid, Header, Image, Icon } from "semantic-ui-react";
import albumCover from "../img/shoes_on_now_deadhead_album.jpeg";

class AlbumTwo extends Component {
  render() {
    return (
      <Segment inverted color="black" id="albumthree">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column computer={6} tablet={16}>
              <Image src={albumCover} fluid bordered />
            </Grid.Column>
            <Grid.Column computer={10} tablet={16} textAlign="center">
              <Header as="h1" textAlign="center" className="main-heading">
                <span>Deadhead</span>
              </Header>
              <Header as="h2" textAlign="center">
                AUG 2022
              </Header>

              <p>
                A 16 track punk rock concept album about doing the garden. FFO:
                NOFX, Descendants, Frenzal Rhomb, Roundup, John Deere, Sandstone
                Slabs etc
              </p>
              <p className="listen-now-buttons">
                <a
                  href="https://shoesonnow.bandcamp.com/album/deadhead"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui button teal small"
                >
                  <Icon name="bandcamp" />
                  Bandcamp
                </a>

                <a
                  href="https://open.spotify.com/album/5iuRv3JpMLGh0e8WZaHCbP?si=HZGdclCJQia850CNkfzX0A"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui button green small"
                >
                  <Icon name="spotify" />
                  Spotify
                </a>

                <a
                  href="https://music.apple.com/gb/album/deadhead/1635846725"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui button grey small"
                >
                  <Icon name="apple" />
                  Apple Music
                </a>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default AlbumTwo;
