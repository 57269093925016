import React, { Component } from "react";
import { Segment, Header, Grid, Divider } from "semantic-ui-react";

class VideosMario extends Component {
  render() {
    return (
      <Segment inverted color="purple" attached id="xmas">
        <Header as="h1" textAlign="center">
          Feeling festive
        </Header>
        <Divider hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>1 DEC 2019</h3>
              <h2>Mega Christmas</h2>
              <h3>We normally write 8-bit pop songs about 90s games</h3>

              <p>
                What we've gone and done here is write an 8-bit Christmas song
                about gettting 90s games for Christmas. In the 90s. Which was
                really the heyday for 90s games in many peoples opinion.
              </p>
            </Grid.Column>

            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/omZzrliLr_U"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Quite a Fright Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default VideosMario;
