import React, { Component } from "react";
import { Image, Header } from "semantic-ui-react";
import sonIcons from "../img/shoes_on_now_icons.jpg";
import sonLogo22 from "../img/shoes_on_now_logo_2022.jpeg";
import sonLogo from "../img/shoes_on_now_logo_2021.jpg";

class Menu extends Component {
  toFixedLength = (item) => {
    return `${item.text.padEnd(18, ".")}${item.key}`;
  };

  menuItems = () => [
    { text: "INTRO", key: "F1", hash: "intro" },
    { text: "2022 ALBUM", key: "F2", hash: "albumthree" },
    { text: "2021 ALBUM", key: "F3", hash: "albumtwo" },
    { text: "XMAS SONG", key: "F4", hash: "xmas" },
    { text: "2018 ALBUM", key: "F5", hash: "album" },
    { text: "LIVE", key: "F6", hash: "live" },
    { text: "BIO", key: "F7", hash: "bio" },
    { text: "BOOKING", key: "F8", hash: "contact" },
  ];

  menuItem = (item) => (
    <li key={item.key}>
      <a href={`#${item.hash}`}>{this.toFixedLength(item)}</a>
    </li>
  );

  render() {
    return (
      <div className="main-nav">
        <Image src={sonLogo22} fluid className="son-logo" />
        <Header as="h1">SHOES ON, NOW!</Header>
        <Header as="h2">
          8-BIT NEW-WAVE PUNK / ODDBALL CHIPTUNE POP / 90s PUNK MEETS GROUND
          FORCE
        </Header>
        <div className="game-menu">
          <div className="main-menu-text">MAIN-MENU</div>
          <ul>{this.menuItems().map((item) => this.menuItem(item))}</ul>
        </div>

        <div className="game-menu hyper">
          <div className="main-menu-text hyper">MEGA-HYPERLINKS</div>
          <ul>
            <li key="hl-1">
              <a
                href="https://open.spotify.com/artist/2IWwPtGqWP04JeJrwEYwpw?si=DRWVCgktTaiWba2AMcfE-Q"
                target="_blank"
                rel="noopener noreferrer"
              >
                SPOTIFY...........GO
              </a>
            </li>
            <li key="hl-2">
              <a
                href="https://shoesonnow.bandcamp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                BANDCAMP..........GO
              </a>
            </li>
            <li key="hl-3">
              <a
                href="https://www.youtube.com/channel/UC_PQ_vsx_cyev4cRVuGtAAA?sub_confirmation=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                YOUTUBE...........GO
              </a>
            </li>
            <li key="hl-4">
              <a
                href="http://patreon.com/shoesonnow"
                target="_blank"
                rel="noopener noreferrer"
              >
                PATREON...........GO
              </a>
            </li>
            <li key="hl-5">
              <a
                href="https://www.facebook.com/shoesonnowband/"
                target="_blank"
                rel="noopener noreferrer"
              >
                FACEBOOK..........GO
              </a>
            </li>
            <li key="hl-6">
              <a
                href="https://www.instagram.com/shoes_on_now/"
                target="_blank"
                rel="noopener noreferrer"
              >
                INSTAGRAM.........GO
              </a>
            </li>
            <li key="hl-7">
              <a
                href="https://twitter.com/shoes_on_now"
                target="_blank"
                rel="noopener noreferrer"
              >
                TWITTER...........GO
              </a>
            </li>
          </ul>
        </div>

        <Image src={sonLogo} fluid className="son-logo" />
        <Image src={sonIcons} size="small" centered className="son-icons" />
      </div>
    );
  }
}

export default Menu;
