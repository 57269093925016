import React, { Component } from "react";
import { Segment, Header, Icon } from "semantic-ui-react";

class Contact extends Component {
  render() {
    return (
      <Segment
        inverted
        color="black"
        attached="bottom"
        id="contact"
        textAlign="center"
      >
        <Header as="h1" textAlign="center" className="main-heading">
          SAY HI
        </Header>
        <Header as="h2" textAlign="center">
          Send us an electronic mail
        </Header>

        <p>
          For bookings, or anything else really, contact us by email... <br />
          <strong>hi.shoes.on.now [.AT.] gmail [.DOT.] com</strong>
          <br />
          <br />
          Or do some like and subscribes...
        </p>

        <p className="listen-now-buttons">
          <a
            href="https://www.facebook.com/shoesonnowband/"
            target="_blank"
            rel="noopener noreferrer"
            className="ui button facebook medium"
          >
            <Icon name="facebook" />
            Facebook
          </a>
          <a
            href="https://www.instagram.com/shoes_on_now/"
            target="_blank"
            rel="noopener noreferrer"
            className="ui button instagram medium"
          >
            <Icon name="instagram" />
            Instagram
          </a>
          <a
            href="https://www.youtube.com/channel/UC_PQ_vsx_cyev4cRVuGtAAA?sub_confirmation=1"
            target="_blank"
            rel="noopener noreferrer"
            className="ui button youtube medium"
          >
            <Icon name="youtube" />
            YouTube
          </a>
        </p>
      </Segment>
    );
  }
}

export default Contact;
