import React, { Component } from "react";
import "./App.css";
import { Container, Grid } from "semantic-ui-react";
import Menu from "./Components/Menu";
import Intro from "./Components/Intro";
import Album from "./Components/Album";
import AlbumTwo from "./Components/AlbumTwo";
import AlbumThree from "./Components/AlbumThree";
import Bio from "./Components/Bio";
import Contact from "./Components/Contact";
import Views from "./Components/Views";
import Live from "./Components/Live";
import VideosMario from "./Components/VideosMario";
import Playlist from "./Components/Playlist";

class App extends Component {
  render() {
    return (
      <Container>
        <Grid stackable columns={2}>
          <Grid.Column largeScreen={4} computer={5} tablet={6}>
            <Menu />
          </Grid.Column>
          <Grid.Column largeScreen={12} computer={11} tablet={10}>
            <Intro />
            <AlbumThree />
            <AlbumTwo />
            <Playlist />
            <VideosMario />
            <Album />
            <Views />
            <Live />
            <Bio />
            <Contact />
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default App;
