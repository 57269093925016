import React, { Component } from "react";
import { Segment, Grid, Header, Image, Icon } from "semantic-ui-react";
import albumCover from "../img/shoes_on_now_backwards_compatible_album_cover.jpg";

class Album extends Component {
  render() {
    return (
      <Segment inverted color="black" attached="top" id="album">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column computer={6} tablet={16}>
              <Image src={albumCover} fluid bordered />
            </Grid.Column>
            <Grid.Column computer={10} tablet={16} textAlign="center">
              <Header as="h1" textAlign="center" className="main-heading">
                <span>BACKWARDS COMPATIBLE</span>
              </Header>
              <Header as="h2" textAlign="center">
                The 2018 debut album
              </Header>

              <p>Listen now on...</p>
              <p className="listen-now-buttons">
                <a
                  href="https://open.spotify.com/album/09Cb5WB94965jMD3iraFQv?si=qziNPn6aQf-gL13S1PQxMA"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui button green small"
                >
                  <Icon name="spotify" />
                  Spotify
                </a>
                <a
                  href="https://shoesonnow.bandcamp.com/album/-"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui button teal small"
                >
                  <Icon name="bandcamp" />
                  Bandcamp
                </a>
                <a
                  href="https://www.youtube.com/watch?v=eejIePM50bo&list=PLXVIITCM-oayxbikRVsyYBkwR8EuEFZ5X"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui button youtube small"
                >
                  <Icon name="youtube" /> YouTube
                </a>
                <a
                  href="https://itunes.apple.com/us/album/backwards-compatible/1444196959?uo=4&app=music&at=1001lry3&ct=dashboard"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui button grey small"
                >
                  <Icon name="apple" />
                  Apple Music
                </a>
              </p>
              <p>Or search on your favourite streaming service.</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Album;
