import React, { Component } from "react";
import { Segment, Header, Grid, Divider } from "semantic-ui-react";

class Playlist extends Component {
  render() {
    return (
      <Segment inverted color="black" attached id="playlist">
        <Header as="h1" textAlign="center">
          [?] The 2021 Album - Lyric Videos
        </Header>
        <Divider hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h2>Big budget</h2>
              <h3>Very Budget</h3>
              <p>Quite confusing for lawyers.</p>
              <p>
                Welcome to SON!'s world of original-and-unoriginal lyric videos
                featuring LYRICS and STILL-IMAGES (NOT VIDEOS) in VIDEO form.
                May contain traces of obscure jokes layered on top of each other
                like a finely crafted cease and desist letter that is also an
                onion.
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/?listType=playlist&list=PLXVIITCM-oazFykuFDN0kMofFBRLqKrar"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Shoes On, Now! Lyric Videos"
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Playlist;
