import React, { Component } from "react";
import { Segment, Header, Grid, Divider } from "semantic-ui-react";

class Live extends Component {
  render() {
    return (
      <Segment inverted color="black" attached id="live">
        <Header as="h1" textAlign="center">
          LIVE...
        </Header>
        <Divider hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h2>Edge of the Wedge, Portsmouth</h2>
              <h3>17 June 2019</h3>

              <p>
                We tried to be a band who didn't play gigs, but failed. This is
                us failing to not play gigs.
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/?listType=playlist&list=PLXVIITCM-oayy5nHqjpsbU0z_4boKrj1Q"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Commercial Opportunities Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Live;
