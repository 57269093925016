import React, { Component } from "react";
import { Segment, Grid, Header, Image, Icon } from "semantic-ui-react";
import albumCover from "../img/question_artwork.jpg";

class AlbumTwo extends Component {
  render() {
    return (
      <Segment inverted color="black" id="albumtwo">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column computer={6} tablet={16}>
              <Image src={albumCover} fluid bordered />
            </Grid.Column>
            <Grid.Column computer={10} tablet={16} textAlign="center">
              <Header as="h1" textAlign="center" className="main-heading">
                <span>[?] &laquo; That's the album title</span>
              </Header>
              <Header as="h2" textAlign="center">
                JAN 2021
              </Header>

              <p>
                In which we imagine MORE of the complex innner emotional lives
                of your favourite video game characters. With a strong bias
                towards plumbing simulation games.
              </p>
              <p className="listen-now-buttons">
                <a
                  href="https://shoesonnow.bandcamp.com/album/-"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui button teal small"
                >
                  <Icon name="bandcamp" />
                  Bandcamp
                </a>

                <a
                  href="https://open.spotify.com/album/5BFkATJfLq6tsmw8gtA7al?si=WpDw-il-RWWEYJywuVLMyw"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui button green small"
                >
                  <Icon name="spotify" />
                  Spotify
                </a>

                <a
                  href="https://music.apple.com/us/album/1545974415"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui button grey small"
                >
                  <Icon name="apple" />
                  Apple Music
                </a>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default AlbumTwo;
