import React, { Component } from "react";
import { Segment, Header, Grid, Divider } from "semantic-ui-react";

class Views extends Component {
  render() {
    return (
      <Segment inverted color="purple" attached id="views">
        <Header as="h1" textAlign="center">
          BACKWARDS COMPATIBLE ALBUM...
        </Header>
        <Header textAlign="center">
          In Nov 2018, we thought it was a good idea to make 24 music videos to release in Dec as an advent calendar (we also have jobs and families). These are 12 of those videos.
        </Header>
        <Divider hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>Track 1</h3>
              <h2>Commercial Opportunities</h2>
              <h3>Sim City 2000. 1993.</h3>

              <p>
                What would it feel like to live in a miniature city that was
                created solely to distract a deposed tyrant from doing more harm
                in the real world? Weirdly, it feels a lot like living in the
                real world circa 2018...
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/eejIePM50bo"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Commercial Opportunities Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>Track 2</h3>
              <h2>Another Signal</h2>
              <h3>Streets of Rage 1 - 3. 1991-94.</h3>

              <p>
                “I left the force, it’s gone bad, now I’m looking to brawl. And
                if I need backup fire then I know who to call. I only really
                count on Blaze, cos everybody looks the same, and the kids all
                wearing rollerblades, on the Streets of Rage.”
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/nvW_XX7gbvw"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Another Signal Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>Track 3</h3>
              <h2>Japan Thailand</h2>
              <h3>Street Fighter II. 1991.</h3>

              <p>
                M. Bison (or Vega if you’re in Japan) is a fascinating human
                being. He’s a bonafide dictator with aspirations of world
                domination, but he’s got a rather unusual plan. Instead of
                invading a neighbouring country, or doing some kind of terrorist
                thing he’s chosen to host a very fair and inclusive sports-like
                competition. You could call it the world cup of kicking and
                punching. This is his song.
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/6e4MlJxyAE0"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Japan Thailand Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>Track 4</h3>
              <h2>Feeling Small</h2>
              <h3>Micro Machines 2: Turbo Tournament. 1994.</h3>

              <p>
                You might not think you could relate emotionally to a tiny toy
                car locked in a never-ending series of head-to-head races, but
                it turns out that you can. In the end, aren’t we all just
                wondering ‘Who the hell needs more than one pool table?’.
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/czPCVt7NZDI"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Feeling Small Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>Track 5</h3>
              <h2>Rearview Mirror</h2>
              <h3>Road Rash II. 1993.</h3>

              <p>
                "Cruisin’ down, Cruisin’ down, Cruisin’ down the road...
                Cruisin’ down, Cruisin’ down, help he's got a chain!"
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/KYLM0e4vhzE"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Rearview Mirror Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>Track 6</h3>
              <h2>Jump Sweep</h2>
              <h3>Street Fighter II. 1991.</h3>

              <p>
                Chun-li was never the best of friends with Vega (I mean M.
                Bison), but she was still invited to the World Cup of Kicking
                and Punching. While on tour she took the time to document her
                unbeatable fighting technique and shared this with her fans.
                This is a very serious song about a complex technical combat
                strategy.
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/0V0h_tmCnMM"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Jump Sweep Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>Track 7</h3>
              <h2>Red Shoes</h2>
              <h3>Sonic the Hedgehog. 1991.</h3>

              <p>
                It was a lonely life for a heroic hedgehog in 1991. There were
                plenty of fluffy creatures to save, but what Sonic really wanted
                was a friend...
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/cakdKsaLaw8"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Red Shoes Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>Track 8</h3>
              <h2>Following You</h2>
              <h3>Lemmings. 1991.</h3>

              <p>
                If you wanted to show the world what a unique and interesting
                person you are, you could probably do this by dying your hair
                green and dressing in full length blue robes. But please try not
                to fall through any trap-doors...
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/Y3i7bJ101go"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Following You Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>Track 9</h3>
              <h2>Oh My</h2>
              <h3>Sonic the Hedgehog 2. 1992.</h3>

              <p>
                Miles “Tails” Prower is the reliable friend Sonic had always
                hoped for, but the feeling isn’t mutual...
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/JV-MqvOM0TE"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Oh My Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>Track 10</h3>
              <h2>Running Away</h2>
              <h3>Theme Park. 1994.</h3>

              <p>
                A young boy goes to a theme park to celebrate his birthday. What
                could be simpler? What could possibly go wrong? Oh wait, he went
                to a Theme Park theme park...
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/VobESe4ffvc"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Running Away Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>Track 11</h3>
              <h2>Go Ballistic</h2>
              <h3>Mortal Kombat 1 - 3 (ish). 1991+.</h3>

              <p>
                “All these fatalities, it’s just so fatalistic. I abhor the
                brutality, but when it’s them against me, I just go ballistic…”
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/VBC-Ezd_VBo"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Go Ballistic Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <h3>Track 12</h3>
              <h2>Incredibly Brave</h2>
              <h3>Gynoug (aka Wings of Wor). 1991.</h3>

              <p>
                Gynoug (or Wings of Wor if you’re in the USA) takes place in a
                traumatic world full of traumatising trauma. That doesn’t bother
                the angel Wor much though, as he is Incredibly Brave. In this
                brave song, he bravely bares his soul and shows us how his
                traumatic encounters have not traumatised him in the slightest.
                Go bravery!
              </p>
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <div className="videoWrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/CwhYD6sRu3g"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  title="Incredibly Brave Music Video"
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Views;
