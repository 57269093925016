import React, { Component } from "react";
import { Segment, Grid, Header, Icon } from "semantic-ui-react";

class Intro extends Component {
  render() {
    return (
      <Segment inverted color="black" attached="top" id="intro">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column computer={16} tablet={16} textAlign="center">
              <Header as="h1" textAlign="center" className="main-heading">
                <span>Shoes On, Now!</span> are a band who were easily
                pigion-holed as an <span>8-bit new-wave punk </span> /{" "}
                <span>Oddball chiptune pop </span> band until they thought it
                would be fun/funny to write a{" "}
                <span>90s punk album about gardening</span>. They are from the
                UK, and enjoy mixing noisy guitar music with gameboys, chiptune
                sounds, and more recently actual drummers.
              </Header>
              <Header as="h3" textAlign="center" className="main-heading">
                They formed in 2018 and wrote an album exploring the inner
                emotional lives of video game characters circa 1991-94, and it
                was fun. So that’s their thing. Except for when their thing is
                'concept albums about gardening'.
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={16} tablet={16} textAlign="center">
              <p className="listen-now-buttons">
                <a
                  href="https://open.spotify.com/artist/2IWwPtGqWP04JeJrwEYwpw?si=1dv8cYxHQTO6oUTrdkUrPg"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui button green medium"
                >
                  <Icon name="spotify" />
                  Follow SON! on Spotify
                </a>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Intro;
